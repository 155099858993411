import React, { useEffect, useState } from "react";
import tw from "tailwind-styled-components";
import Config from "../Config.js";
import axios from "axios";

import { Field, Formik, Form } from "formik";
import MenuItem from "@mui/material/MenuItem";
import { toast, Bounce } from "react-toastify";
import { useMutation } from "react-query";
import { Outlet } from "react-router-dom";
import Hero from "../components/Hero.jsx";
// import HeroInner from "../components/trash/HeroInner.jsx";
//import HeaderInner from "../components/HeaderInner";
import UserDetailsComp from "../components/UserDetails.jsx";
// import SuccessLendersComp from "../components/trash/SuccessLenders.jsx";
import ErrorComp from "../components/Error.jsx";
import PrivacyModel from "../components/PrivacyModel.jsx";
import TermsModel from "../components/TermsModel.jsx";
//import Topup from "../components/Topup";
// import TopupNew from "../components/trash/TopupNew.jsx";
import { names } from "../components/DoughnutChart.js";
import Select from "@mui/material/Select";
import { formatAmount } from "../components/DoughnutChart.js";
// import {
//   Page,
//   BoxContainer,
//   BoxTitle,
//   Underline,
// } from "../components/styles/PageStyles.jsx";
import {
  SubmitBtn,
  InputGroup,
  FieldWrapper,
  Label,
  CombineInputGroup,
} from "../components/styles/InputStyles.jsx";
import { useTranslation } from "react-i18next";

import Loading from "../components/Loading.jsx";
// import { max } from "date-fns";
import LendersAndTopup from "../components/LendersAndTopup.jsx";

// !HOME PAGE
const RegisterForm = () => {
  const { t, i18n } = useTranslation();
  const [data, setData] = useState(null);
  // const [lenderData, setLenderData] = useState(null);
  const [isStepOne, setIsStepOne] = useState(false);
  const [isStepTwo, setIsStepSuccess] = useState(false);
  const [showPrivacyModel, setOpenPrivacyModel] = useState(false);
  const [showTermsModel, setOpenTermsModel] = useState(false);
  const [isStepError, setIsStepError] = useState(false);
  const [lenders, setLenders] = useState([
    // { name: "xyz", email: "mail@mail.com" },
    // { name: "xyz", email: "mail@mail.com" },
  ]);
  const [loanAmount, setLoanAmount] = useState(0);
  const [topupData, setTopupData] = useState(null);
  // console.log(topupData);
  // console.log({ topupAmount });
  useEffect(() => {
    if (!isStepOne && isStepTwo && data) {
      mutatePayTopupFees();
    }
  }, [topupData]);

  const InitialValue = {
    email: "",
    firstName: "",
    lastName: "",
    mpesa: "",
    nationalId: "",
    status: "",
  };

  //------- Send Login Request -------
  const LoginPostFunction = async (values) => {
    const body = new FormData();
    body.append("first_name", values.firstName);
    body.append("last_name", values.lastName);
    body.append("email", values.email);
    body.append("mpesa_number", values.mpesa);
    body.append("national_id_number", values.nationalId);
    body.append("loan_type", values.loanType);
    return await axios.post(`${Config.apiUrl}/checkEligibility`, body, {
      headers: {
        authorization: Config.AxiosConfig.headers.authorization,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  // ! ---------------------CHECK ELIGIBILITY LOGIC-------------------------------
  const onSuccess = (res) => {
    //console.log(res.data);
    if (res?.data?.status === "OK") {
      setData(res.data.data);
      setIsStepOne(true);
    } else toast.error(res?.data?.msg || "Error");
  };

  const onError = (res) => {
    //setOpenUploadModel(false);
    toast.error(res?.response?.data?.msg || "An Error Occured");
  };

  const { isLoading: isLoadingReg, mutate: mutateLogin } = useMutation(
    LoginPostFunction,
    {
      onSuccess: onSuccess,
      onError: onError,
    }
  );
  //------- Send Login Request -------

  const SubmitHandler = (values) => {
    //console.log(values.mpesa);
    if (
      !values.mpesa ||
      values.mpesa.toString().length < 9 ||
      values.mpesa.toString().length > 9
    ) {
      return toast.error(
        "Please enter valid Mpesa Number without country code"
      );
    }
    mutateLogin({ ...values });
  };

  //------- Send Pay Fees Request -------
  const PayFeesPostFunction = async (values) => {
    const body = new FormData();
    body.append("loanId", data.loanId);
    return await axios.post(`${Config.apiUrl}/payfees`, body, {
      headers: {
        authorization: Config.AxiosConfig.headers.authorization,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const onPayFeesSuccess = (res) => {
    //console.log(res.data);
    setIsStepOne(false);
    setIsStepError(false);
    setIsStepSuccess(false);
    if (res?.data?.status === "OK") {
      //setData(res.data.data);
      setLoanAmount(data.loanAmount);
      setLenders(res.data.lenders);
      setIsStepSuccess(true);
    } else {
      toast.error(res?.data?.msg || "Error");
      setIsStepError(true);
      //setIsStepSuccess(true); // For Testing Only
    }
  };

  const onPayFeesError = (res) => {
    //setOpenUploadModel(false);
    //console.log(res);
    toast.error(res?.response?.data?.msg || "An Error Occured");
  };

  const { isLoading: isLoadingPayFees, mutate: mutatePayFees } = useMutation(
    PayFeesPostFunction,
    {
      onSuccess: onPayFeesSuccess,
      onError: onPayFeesError,
    }
  );
  //------- Send Pay Fees Request -------

  const payFeesHandler = () => {
    mutatePayFees();
  };

  //------- Send Loan Topup Request -------
  const PayTopupFeesPostFunction = async () => {
    // console.log(topupData);
    const body = new FormData();

    body.append("loanId", data.loanId);
    //body.append("fees", topupData?.tuAmount);
    body.append("fees", topupData?.fees);

    return await axios.post(`${Config.apiUrl}/loantopup`, body, {
      headers: {
        authorization: Config.AxiosConfig.headers.authorization,
        // "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const onPayTopupFeesSuccess = (res) => {
    // console.log("Success");
    //console.log(res.data);
    // setIsStepOne(false);
    // setIsStepError(false);
    //setIsStepSuccess(false);

    if (res.data.status === "OK") {
      //setData(res.data.data);
      // console.log(loanAmount, topupAmount);
      // setLenders(res.data.lenders);

      setLoanAmount((prev) => {
        // console.log(prev, topupAmount);
        return prev + topupData["tuAmount"];
      });
      // setIsStepSuccess(true);
    } else {
      toast.error(res.data.msg || "Error");
    }
  };

  const onPayTopupFeesError = (res) => {
    // console.log("Error");
    //setOpenUploadModel(false);
    return toast.error(res?.data?.msg || "An Error Occured");
    // console.log(loanAmount, topupAmount);
    // setLoanAmount((prev) => prev + topupAmount);
  };

  const { isLoading: isLoadingPayTopupFees, mutateAsync: mutatePayTopupFees } =
    useMutation(PayTopupFeesPostFunction, {
      onSuccess: onPayTopupFeesSuccess,
      onError: onPayTopupFeesError,
    });
  //------- Send Pay Fees Request -------
  // console.log(topupAmount);
  // const payTopupFeesHandler = async (data) => {
  //   // console.log("this is the topup amount!: --", +topup);
  //   // setTopupAmount(()=>topup);
  //   // setTopupAmount(topup);
  //   setTopupData(data);
  //   // console.log("updated topup amount " + topupAmount);
  //   // setFees(amount);
  //   // console.log("Topup amount - " + topupAmount);
  //   //console.log(amount);
  //   //setIsStepOne(false);
  //   //setIsStepError(false);
  //   //setIsStepSuccess(false);
  //   // await postPayTopupFees(amount);
  // };

  //! -------Notification in every 8 seconds -------------
  // const screenWidth = window.innerWidth < 768
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!document.hidden) {
        showToast(
          `${
            names[Math.floor(Math.random() * (names.length - 1))]
          } just a got a loan of ${formatAmount(
            Math.floor(Math.random() * (60000 - 5000 + 1) + 5000)
          )}`
        );
      }
    }, 20000);

    return () => clearInterval(intervalId);
  }, []);
  const screenSize = window.innerWidth;
  const showToast = (message) => {
    toast.info(message, {
      // position: screenWidth ? toast.POSITION.TOP_CENTER :toast.POSITION.BOTTOM_LEFT,
      position:
        screenSize < 768
          ? toast.POSITION.TOP_CENTER
          : toast.POSITION.BOTTOM_LEFT,
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnFocusLoss: false,
      pauseOnHover: true,
      draggable: true,
      theme: "dark",
      transition: Bounce,
    });
  };

  const openPrivacyModel = () => {
    setOpenPrivacyModel(true);
  };

  return (
    // ? Main container

    <Box>
      {showPrivacyModel && (
        <PrivacyModel setOpenPrivacyModel={setOpenPrivacyModel} />
      )}
      {showTermsModel && <TermsModel setOpenTermsModel={setOpenTermsModel} />}
      {!data && (
        <>
          <Hero />
          <Outlet />
          <EligibilityFormComp
            InitialValue={InitialValue}
            SubmitHandler={SubmitHandler}
            openPrivacyModel={openPrivacyModel}
            setOpenTermsModel={setOpenTermsModel}
          />
        </>
      )}
      {data && isStepOne && (
        <>
          <Hero />
          <Outlet />
          <UserDetailsComp
            data={data}
            payFeesHandler={payFeesHandler}
            isLoadingPayFees={isLoadingPayFees}
            openPrivacyModel={openPrivacyModel}
            setOpenTermsModel={setOpenTermsModel}
          />
        </>
      )}
      {/* {data && isStepTwo && (
        <>
        <div className="mask h-full w-full lg:w-1/2 space-y-6 hidden md:block">
        <HeroInner
          data={data}
          lenderData={lenderData}
          payTopupFeesHandler={payTopupFeesHandler}
          isLoadingPayTopupFees={isLoadingPayTopupFees}
        />  
         <TopupNew
          data={data}
          loanAmount={loanAmount}
          lenderData={lenderData}
          payTopupFeesHandler={payTopupFeesHandler}
          isLoadingPayTopupFees={isLoadingPayTopupFees}
        />  
        </div> 
        <div className="mask mb-4 grid h-full w-full lg:w-1/2 space-y-8  md:hidden">
        <HeroInner
          data={data}
          lenderData={lenderData}
          payTopupFeesHandler={payTopupFeesHandler}
          isLoadingPayTopupFees={isLoadingPayTopupFees}
        />  
        </div>
        <SuccessLendersComp
          data={data}
          loanAmount={loanAmount}
          lenders={lenders}
          lenderData={lenderData}
          payTopupFeesHandler={payTopupFeesHandler}
          isLoadingPayTopupFees={isLoadingPayTopupFees}
          openPrivacyModel={openPrivacyModel}
          setOpenTermsModel={setOpenTermsModel}
        />
        <div className="mask grid h-full w-full lg:w-1/2 space-y-8 md:hidden">
        <TopupNew
          data={data}
          loanAmount={loanAmount}
          lenderData={lenderData}
          payTopupFeesHandler={payTopupFeesHandler}
          isLoadingPayTopupFees={isLoadingPayTopupFees}
        />  
        </div>
        </>
      )} */}
      {data && isStepTwo && (
        <LendersAndTopup
          lenders={lenders}
          userData={data}
          loanAmount={loanAmount}
          // setTopupAmount={setTopupAmount}
          setTopupData={setTopupData}
          // payTopupFeesHandler={payTopupFeesHandler}
          isLoadingPayTopupFees={isLoadingPayTopupFees}
          setOpenTermsModel={setOpenTermsModel}
        />
      )}
      {data && isStepError && (
        <>
          <Hero />
          <Outlet />
          <ErrorComp
            data={data}
            payFeesHandler={payFeesHandler}
            isLoadingPayFees={isLoadingPayFees}
            openPrivacyModel={openPrivacyModel}
            setOpenTermsModel={setOpenTermsModel}
          />
        </>
      )}
    </Box>
  );
};

// ! FORM COMPONENT INSIDE HOME PAGE
const EligibilityFormComp = ({
  InitialValue,
  SubmitHandler,
  openPrivacyModel,
  setOpenTermsModel,
}) => {
  return (
    // <div className="lg:mx-12 card py-4 bg-neutral-700 shadow-md shadow-black w-full h-full rounded-xl flex flex-col justify-center px-6">

    // <TextWrapper>
    //   <Container>
    //     <Center>
    //       <div className="lg:mx-12 card py-4 bg-neutral-700 shadow-md shadow-black w-full h-full rounded-xl flex flex-col justify-center px-6">
    <div className="h-full mt-4 w-full lg:w-1/2 flex items-center justify-center font-poppins ">
      <div className="lg:ml-12 card py-4 bg-neutral-700 shadow-md shadow-black w-full h-full rounded-xl flex flex-col justify-center px-6">
        {/* <div className="flex space-x-6">
          <Logo src={Images.GovtLogo} alt="logo" />
          <VerticalBorder />
          <Logo src={Images.Logo} alt="logo" />
        </div> */}
        <Title>{"Find Your Loan Eligibility"}</Title>
        <SubTitle>
          {"You can qualify for Ksh. 5,000 to 1,00,000 loan to M-PESA."}
        </SubTitle>
        <Formik
          initialValues={InitialValue}
          onSubmit={SubmitHandler}

          // enableReinitialize={isInitialValueSaved}
        >
          {(formikProps) => (
            <>
              <h1>{formikProps.isLoading}</h1>
              <Form className="w-full">
                <FormContainer>
                  <CombineInputGroup>
                    <InputGroup>
                      <Label htmlFor="name">{"First Name"}</Label>
                      <FieldWrapper>
                        <Field
                          required
                          name="firstName"
                          id="firstName"
                          autoComplete="off"
                        />
                      </FieldWrapper>
                    </InputGroup>

                    <InputGroup>
                      <Label htmlFor="lastName">{"Last Name"}</Label>
                      <FieldWrapper>
                        <Field
                          required
                          name="lastName"
                          id="lastName"
                          autoComplete="off"
                        />
                      </FieldWrapper>
                    </InputGroup>
                  </CombineInputGroup>

                  <CombineInputGroup>
                    <InputGroup>
                      <Label htmlFor="mpesa">{"M-PESA Number"}</Label>
                      <FieldWrapper>
                        <Field
                          required
                          name="mpesa"
                          id="mpesa"
                          type="number"
                          autoComplete="off"
                        />
                      </FieldWrapper>
                    </InputGroup>

                    <InputGroup>
                      <Label htmlFor="email">{"Email"}</Label>
                      <FieldWrapper>
                        <Field
                          name="email"
                          id="email"
                          type="email"
                          autoComplete="off"
                        />
                      </FieldWrapper>
                    </InputGroup>
                  </CombineInputGroup>

                  <CombineInputGroup>
                    <InputGroup>
                      <Label htmlFor="nationalId">{"National ID Number"}</Label>
                      <FieldWrapper>
                        <Field
                          required
                          name="nationalId"
                          id="nationalId"
                          autoComplete="off"
                        />
                      </FieldWrapper>
                    </InputGroup>

                    <InputGroup>
                      <Label htmlFor="loanType">Select Loan Type</Label>
                      <FieldWrapper $select={true}>
                        <Field
                          required
                          name="loanType"
                          id="loanType"
                          autoComplete="off"
                          className="text-white"
                        >
                          {(props) => (
                            <Select
                              required
                              className="w-full h-full  text-white"
                              style={{ color: "white" }}
                              {...props.field}
                            >
                              <MenuItem value="1">Emergency Loan</MenuItem>
                              <MenuItem value="2">Car Loan</MenuItem>
                              <MenuItem value="3">Personal Loan</MenuItem>
                              <MenuItem value="4">Phone Loan</MenuItem>
                            </Select>
                          )}
                        </Field>
                      </FieldWrapper>
                    </InputGroup>
                  </CombineInputGroup>
                  <SubTitle>
                    {
                      "No CRB Check. No Guarantors. Disbursed to M-PESA. No Paperwork."
                    }
                  </SubTitle>
                </FormContainer>
                <div className="mt-8 flex">
                  <SubmitBtn type="submit">Check Eligibility</SubmitBtn>
                </div>

                <p className="text-left text-[10px] sm:text-xs  text-slate-200 mt-8 lg:mt-12 mb-4">
                  By submitting, you confirm that you accepted our{" "}
                  <span
                    className="text-gradient"
                    onClick={() => openPrivacyModel(true)}
                  >
                    Privacy Policy
                  </span>{" "}
                  &{" "}
                  <span
                    className="text-gradient"
                    onClick={() => setOpenTermsModel(true)}
                  >
                    Terms and Conditions.
                  </span>
                </p>
              </Form>
            </>
          )}
        </Formik>
        {/* </Center> */}
        {/* <Link className="mt-2" to="/register">
        <p className="text-sm text-white pb-5 ">
          {"By Submitting you confirm that you accept the "}{" "}
          <span className="text-blue-700 font-medium">{"Terms & Conditions"}</span>
        </p>
      </Link> */}
        {/* <SmallText>
        Virtual health professionals to help with all your rapid testing needs.
      </SmallText> */}
        {/* </Container>
  </TextWrapper> */}
      </div>
    </div>
  );
};

const FormContainer = tw.div` grid max-w-sm md:max-w-xl  mt-8 w-full gap-5 md:gap-5`;
//const Login = tw.section`h-screen flex  bg-white justify-center items-center`;
const Box = tw.div`bg-neutral-900 w-full h-full flex flex-col lg:flex-row p-6 lg:px-7 lg:py-10`;
//const Container = tw.div`pl-10 flex flex-col h-full w-full justify-center overflow-y-auto items-start   `;
// const Container = tw.div`flex flex-col h-full lg:w-5/6 w-full items-start   `;
// const Logo = tw.img`w-20 h-20 object-contain mb-10`;
//const TextWrapper = tw.div`w-full h-full  md:w-1/2 relative md:overflow-x-hidden overflow-y-auto md:pl-12`;
//const TextWrapper = tw.div`h-full relative md:pl-12`;
// const TextWrapper = tw.div`w-full lg:w-1/2 grid place-items-center font-poppins lg:pt-0`;
// const TextWrapper = tw.div`h-full  w-full lg:w-1//2 flex items-center justify-center font-poppins`;
// const TextOverlay = tw.section`absolute -right-40 top-0 h-full  `;

const Title = tw.h1`text-xl sm:text-2xl font-light text-white tracking-wider`;

const SubTitle = tw.h3`mt-1 sm:mt-2 tracking-wider text-white text-sm `;

// const Button = tw.button` mt-10 h-20 w-96  md:w-72 lg:w-96 px-5   text-gray-800 flex items-center border rounded-md bg-white z-10 loginBtn  `;

// const ImageWrapper = tw.div`hidden h-full  md:block md:w-1/2  `;
// const LoginImage = tw.img`h-full w-full object-cover object-center`;

// const Center = tw.div`h-full w-full  py-10 pt-10 mb-2  flex flex-col  `;

// const SmallText = tw.p`text-xs font-normal text-gray-400 `;

// const VerticalBorder = tw.div`w-0.5 h-14 bg-gray-300 mt-4`;

// const LanguageWrapper = tw.div`absolute top-5 right-5 flex space-x-2.5 items-center z-50`;

// const RowWrapper = tw.div`flex space-x-2.5 items-center z-50 mt-14 mb- justify-between w-full`;
// const Terms = tw.h3`tracking-wider  text-white text-xs  float-right`;

// const LangButton = tw.button`
// ${(p) =>
//   p.$active
//     ? "bg-cyan-600 text-white"
//     : "hover:bg-gray-300 bg-gray-200 text-gray-800"}
// px-4 py-2 text-xs  cursor-pointer  rounded-full `;

export default RegisterForm;
