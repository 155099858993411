import React, { useState } from "react";
import Model from "./Model";
import tw from "tailwind-styled-components";
import { Link } from "react-router-dom";
import { privacyContent } from "./DoughnutChart";

const PrivacyModel = ({
  setOpenPrivacyModel,
}) => {

  return (
    // <Model width={"w-11/12 max-w-lg"} setOpenModel={setOpenPrivacyModel}>
    //       <Title>Loan Policies</Title>

    //       <Content>
    //           We care about your privacy, data protection, and confidentiality. This Privacy policy (hereinafter – “Privacy policy”) states, what information we collect, for which purposes and means, and what are your rights.
    //           When is this Privacy policy applicable?

    //           This Privacy policy applies when:

    //           You use or have expressed the intention to use any of our Services; You represent a Client (for example as a guarantor, the payer, authorized representative, next of kin etc.) or you have been indicated as the contact person for the Client;

    //           You have used our Services in the past and we are required to retain this information;

    //           You contact us or visit any of our representative and customer service offices; You visit our website;

    //           In any other way provide us with your personal data for any of the data processing purposes set in this Privacy policy.
    //       </Content>
    //       <Cancel onClick={() => setOpenPrivacyModel(false)}>Close</Cancel>

    // </Model>
    <Model content={privacyContent} setOpenModel={setOpenPrivacyModel}/>
  );
};

const Title = tw.h2` text-lg md:text-xl lg:text-2xl text-gray-600 font-medium mb-6 text-center`;
const Content = tw.div`w-full flex items-center justify-center space-x-10 mt-8`;
const Cancel = tw.button`mt-4 float-right px-8 py-3 text-sm bg-gray-100 text-gray-600 rounded hover:bg-gray-200`;

export default PrivacyModel;