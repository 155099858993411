import React from "react";
//import ReactDOM from "react-dom/client";
// import Submit from "./pages/Submit.jsx";
import Home from "./pages/Home.js";
// import Success from "./pages/Success.jsx";
import Error from "./components/trash/Error.jsx";
import "./index.css";
import { Route, Routes } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { getToken } from "firebase/messaging";
import { useEffect, useState } from "react";
import { registerSw, reqPermission, onMessageListener } from "./firebase";
import { getToken, onMessage } from "firebase/messaging";
import axios from "axios";
import Config from "./Config.js";

const { REACT_APP_VAPID_ID } = process.env;
function App() {
  // onMessageListener()
  //   .then((payload) => {
  //     console.log(payload);
  //   })
  //   .catch((err) => {
  //     console.log(err.message);
  //   });
  async function onLoad() {
    try {
      const register = await registerSw();
      const token = await reqPermission(register);
      // console.log(token);
      if (token) {
        try {
          const body = new FormData();
          body.append("token", token);
          const res = await axios.post(`${Config.apiUrl}/insertToken`, body, {
            headers: {
              authorization: Config.AxiosConfig.headers.authorization,
              "Content-Type":
                "application/x-www-form-urlencoded; charset=UTF-8",
            },
          });
          console.log(res.data);
        } catch (error) {
          console.log("API-Error->", error.message);
        }
      }
    } catch (err) {
      console.log("token-error->", err.message);
    }
  }
  useEffect(() => {
    onLoad();
  }, []);
  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={10000}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
      />
      <Routes>
        <Route path="/" element={<Home />}></Route>
        {/* <Route path="" element={<FormElement />} /> */}
        {/* <Route path="submit" element={<Submit />}></Route>
        <Route path="/success" element={<Success />} /> */}
        <Route path="/error" element={<Error />} />
      </Routes>
    </>
  );
}
export default App;
