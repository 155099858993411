import tw from "tailwind-styled-components";
import HeaderComp from "./Header";
import Success from "./trash/Success";
import { formatAmount } from "./DoughnutChart";
import { useEffect, useState } from "react";
import LenderTable from "./LenderTable";
import Loading from "./Loading";
// import { RingLoader, PulseLoader } from "react-spinners";
const LendersAndTopup = ({
  userData,
  loanAmount,
  lenders,
  setTopupData,
  // payTopupFeesHandler,
  setOpenTermsModel,
  isLoadingPayTopupFees,
}) => {
  const [fees, setFees] = useState([20, 15, 10, 7, 5]);
  const [active, setActive] = useState(null);
  const [tuAmount, setTuAmount] = useState([800, 600, 400, 300, 100]);
  // const lenders = [

  //   {
  //     name: "John Doe",
  //     email: "lfhjsf@malkfjs ",
  //   },
  //   {
  //     name: "John Doe",
  //     email: "xxxxxx@xxxxxxx ",
  //   },
  //   {
  //     name: "John Doe",
  //     email: "xxxxxx@xxxxxxx ",
  //   },
  //   {
  //     name: "John Doe",
  //     email: "xxxxxx@xxxxxxx ",
  //   },
  //   {
  //     name: "John Doe",
  //     email: "xxxxxx@xxxxxxx ",
  //   },
  //   {
  //     name: "John Doe",
  //     email: "xxxxxx@xxxxxxx ",
  //   },
  //   {
  //     name: "John Doe",
  //     email: "xxxxxx@xxxxxxx ",
  //   },
  //   {
  //     name: "John Doe",
  //     email: "xxxxxx@xxxxxxx ",
  //   },
  // ]
  useEffect(() => {
    if (userData.loanType === "Emergency Loan") {
      setFees([60, 50, 40, 30, 20]);
      setTuAmount([4000, 3000, 2000, 1500, 1000]);
    } else if (userData.loanType === "Car Loan") {
      setFees([100, 80, 60, 40, 20]);
      setTuAmount([5000, 3000, 2000, 1000, 500]);
    } else if (userData.loanType === "Personal Loan") {
      setFees([30, 20, 15, 7, 5]);
      setTuAmount([1200, 700, 500, 300, 100]);
    } else {
      setFees([20, 15, 10, 7, 5]);
      setTuAmount([800, 600, 400, 300, 100]);
    }
    //fetchLenderList();
  }, []);

  const handleClick = (idx) => {
    setActive(idx);
    // payTopupFeesHandler(fees[idx], tuAmount[idx])
    // payTopupFeesHandler()
    return setTopupData({ fees: fees[idx], tuAmount: tuAmount[idx] });
  };

  return (
    <Container>
      <TopUpContainer>
        <div className="hidden lg:block">
          <HeaderComp />
        </div>
        {/* <Success /> */}
        <div className="mt-4 mb-4 top- flex flex-col gap-6 md:text-base sm:text-sm text-sm text-gray-900">
          {Array.from({ length: 5 }).map((elem, idx) => (
            <Topup key={idx}>
              <span className="">
                Add <b>{formatAmount(fees[idx])} </b> to increase your loan
                limit from <b>{formatAmount(loanAmount)} to </b>
                <b>{formatAmount(loanAmount + tuAmount[idx])}</b>
              </span>
              <UpgradeButton
                // onClick={() => payTopupFeesHandler(fees[idx], tuAmount[idx])}
                onClick={() => handleClick(idx)}
                disabled={isLoadingPayTopupFees}
              >
                {/* {isLoadingPayTopupFees && active === idx ? (
                  <PulseLoader color={"#ffffff"} size={6} />
                ) : ( */}
                {isLoadingPayTopupFees && active === idx ? (
                  <Loading
                    color="white"
                    width={20}
                    height={20}
                    noPadding={true}
                  />
                ) : (
                  "Upgrade"
                )}
                {/* )} */}
              </UpgradeButton>
            </Topup>
          ))}
        </div>
      </TopUpContainer>
      <LendersContainer>
        {" "}
        <Card>
          <Header>
            <div className="success p-4 bg-[#40d194]/20 text-[#40d194] mt-4 rounded-full">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-12 h-12"
              >
                <path
                  fillRule="evenodd"
                  d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <p className="text-2xl font-medium text-gray-200">
              Payment Success!
            </p>
            <Title>
              Hello{" "}
              <span className="lg:text-lg sm:text-base text-sm font-extrabold capitalize">
                {userData.name.toLowerCase()}
                {", "}
              </span>
              We have received your loan request for{" "}
              <b>{formatAmount(loanAmount)}</b>. Below is the lender list:
            </Title>
          </Header>
          <LenderTable list={lenders} itemsPerPage={3} />
          {/* <Footer>
            <span className="font-medium text-base text-white">
              Pay <b>Ksh.100</b> to get more lenders info{""}
            </span>
            <button className="bg-emerald-500 px-4 py-2 rounded-md text-white">
              Pay
            </button>
          </Footer> */}
          <Footer>
            <button
              onClick={() => setOpenTermsModel(true)}
              className="text-gradient font-bold text-base"
            >
              Term and Conditions{" "}
            </button>
          </Footer>
        </Card>
      </LendersContainer>
      <MobileNav>
        <HeaderComp />
      </MobileNav>
    </Container>
  );
};
export default LendersAndTopup;

const Container = tw.div` w-full flex flex-col-reverse lg:flex-row gap-8   `;
const Card = tw.div` h-full grid place-items-center gap-4`;
const Title = tw.h1`text-center lg:text-lg sm:text-base text-sm text-gray-100`;
// const SubTitle = tw.h2`text-center text-base font-medium text-gray-800`;
const Header = tw.div`grid place-items-center gap-4`;
const Footer = tw.div`flex justify-between  gap-4 items-center py-4 px-1 text-sm`;
const TopUpContainer = tw.div`flex-1 bg-stone-700/60 backdrop-blue-2xl flex flex-col gap-12 rounded-xl lg:h-[600px] my-auto px-8`;
const LendersContainer = tw.div`flex-1 px-2 md:px-8 lg:h-[600px] my-auto bg-stone-700/60 backdrop-blue-2xl rounded-xl `;
const MobileNav = tw.div`lg:hidden block backdrop-blur-xl rounded-xl pl-4`;
const UpgradeButton = tw.button`flex justify-center items-center w-full sm:w-24 px-3 h-10 bg-violet-500 rounded-lg text-white disabled:bg-gray-400 disabled:cursor-not-allowed`;
const Topup = tw.div`flex flex-col sm:flex-row justify-between sm:items-center gap-4 sm:gap-8 text-white`;
