import { useEffect, useRef } from "react";
const Error = () => {
  const ref = useRef();
  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView({ behaviour: "smooth" });
    }
  }, []);
  return (
    <div ref={ref} className="h-full mt-10 lg:mt-0 w-full lg:w-1/2 flex items-center justify-center font-poppins">
      <div className="card bg-neutral-900 shadow-md shadow-black w-full md:w-1/2 lg:w-3/4  lg:h-5/6 h-[70vh] rounded-xl flex flex-col justify-center px-6 gap-2 py-2">
        <div className="title  flex justify-center flex-col items-center gap-2">
          <div className="success p-4 bg-rose-500/20 text-rose-500 rounded-full">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-12 h-12"
            >
              <path
                fillRule="evenodd"
                d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-1.72 6.97a.75.75 0 1 0-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 1 0 1.06 1.06L12 13.06l1.72 1.72a.75.75 0 1 0 1.06-1.06L13.06 12l1.72-1.72a.75.75 0 1 0-1.06-1.06L12 10.94l-1.72-1.72Z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          <p className="text-2xl font-medium text-gray-300">Payment Failed!</p>
        </div>
        <hr className="border-gray-700/60 w-5/6 m-auto my-6" />
        <div className="flex   flex-col text-gray-300 text-pretty text-center  text-xs sm:text-base leading-tight gap-2 items-center">
          <p>
            Hello Ramesh, an error occurred while we tried to process your loan
            request of
            <b> Ksh. 7,527.</b>
          </p>
          <p>Please try again!</p>

          <span>Thankyou for choosing us!</span>
        </div>
        <hr className="w-5/6 border-dashed m-auto my-6 border-gray-600" />
        <div className="top-up flex flex-col gap-6 text-sm text-gray-300">
          <button className="bg-rose-500 p-4 rounded-lg w-3/4 m-auto ">
            Retry - Loan Application
          </button>
          <p className="text-center text-xs text-slate-200">
            By submitting you confirm that you accept the{" "}
            <span className="text-gradient">Privacy Policy</span> and{" "}
            <span className="text-gradient">Terms and Conditions</span>
          </p>
        </div>
      </div>
    </div>
  );
};
export default Error;
