import { useEffect, useRef } from "react";
//import HomeHeader from "../components/Header";
import tw from "tailwind-styled-components";
import Hero from "../Hero";
import { Outlet } from "react-router-dom";

const Success = () => {
  const ref = useRef();
  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView({ behaviour: "smooth" });
    }
  }, []);
  return (

    <Box>
      <Hero />

    <div
      ref={ref}
      className="h-full w-full lg:w-1/2 flex items-center justify-center font-poppins"
    >
      <div className="card bg-neutral-700 shadow-md shadow-black w-full md:w-1/2 lg:w-3/4  h-5/6 rounded-xl flex flex-col justify-center px-6 gap-2 py-4 mt-4">
        <div className="title  flex justify-center flex-col items-center gap-2">
          <div className="success p-4 bg-[#40d194]/20 text-[#40d194] rounded-full">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-12 h-12"
            >
              <path
                fillRule="evenodd"
                d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          <p className="text-2xl font-medium text-gray-300">Payment Success!</p>
        </div>
        <hr className="border-gray-700/60 w-5/6 m-auto my-6" />
        <div className="flex   flex-col text-gray-300 text-pretty text-center  text-xs sm:text-base leading-tight gap-2 items-center">
          <p>
            Hello Ramesh, we have recieved your loan request for{" "}
            <b>Ksh. 7,527.</b>
          </p>
          <p>Your loan will be prcoessed in 5-7 working days!</p>
          <div className=" flex gap-2 items-center">
            <p>Loan tracking ID:</p>
            <span className="font-bold md:text-base text-sm ">RK-87542</span>
          </div>
          <span>Thankyou for choosing us!</span>
        </div>
        <hr className="w-5/6 border-dashed m-auto my-6 border-gray-600" />
        <div className="top- flex flex-col gap-6 text-sm text-gray-300">
          <div className="1 flex justify-between items-center">
            <span className="w-3/4">
              Add <b>Ksh. 20</b> to increase your loan limit from Ksh. 7,527 to{" "}
              <b>Ksh. 10,027</b>
            </span>
            <button className=" px-4 py-2 text-gray-200  bg-blue-500 rounded-md">
              Upgrade
            </button>
          </div>
          <div className="1 flex justify-between items-center">
            <span className="w-3/4">
              Add <b>Ksh. 20</b> to increase your loan limit from Ksh. 7,527 to{" "}
              <b>Ksh. 10,027</b>
            </span>
            <button className=" px-4 py-2 text-gray-200  bg-blue-500 rounded-md">
              Upgrade
            </button>
          </div>
          <div className="1 flex justify-between items-center">
            <span className="w-3/4">
              Add <b>Ksh. 20</b> to increase your loan limit from Ksh. 7,527 to{" "}
              <b>Ksh. 10,027</b>
            </span>
            <button className=" px-4 py-2 text-gray-200  bg-blue-500 rounded-md">
              Upgrade
            </button>
          </div>
        </div>
      </div>
    </div>
    </Box>
  );
};

const Box = tw.div`bg-neutral-900 w-screen h-full flex flex-col lg:flex-row p-6 lg:p-10`;

export default Success;
