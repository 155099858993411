const Header = () => {
  return (
    <div className="h-20 lg:h-28 flex items-center">
      <div className="logo text-xl md:text-2xl pl-2 font-bold text-slate-200 flex flex-col border-l-4 border-l-slate-200">
        <span>LOAN</span>
        <span>BOOSTER</span>
      </div>
    </div>
  );
};
export default Header;
