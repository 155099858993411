// import React from "react";
// import tw from "tailwind-styled-components";
// //  import Images from "../Images";

// const Model = ({ width, setOpenModel, children }) => {
//   const closeModel = () => setOpenModel(false);

//   const getCurrentTarget = (e) => {
//     const targetClass = Array.from(e.target.classList);
//     if (targetClass.includes("addCommentModel")) closeModel();
//   };

//   return (
//     <Wrapper onClick={getCurrentTarget}>
//       <Box $width={width} style={{ maxHeight: "80vh", overflowY: "auto" }}>
//         {/* <Cross src={Images.CrossBlack} alt="cross" onClick={closeModel} /> */}
//         {children}
//       </Box>
//     </Wrapper>
//   );
// };

// const Wrapper = tw.div`
// fixed top-0 higher-z-index right-0 bottom-0 left-0 BlackTransparentBg sm:px-3  h-screen flex justify-center items-center  z-10 addCommentModel overflow-y-auto`;

// const Box = tw.div`
// ${(p) => (p.$width ? ` bg-white ${p.$width} ` : " bg-white w-11/12 lg:w-3/5")}
//  mx-auto h-auto  rounded-md px-6 py-10 lg:p-12 relative `;

// const Cross = tw.img`
// absolute top-5 right-5 w-3.5 z-10 cursor-pointer`;

// export default Model;
import { CgClose } from "react-icons/cg";
import tw from "tailwind-styled-components";

const Modal = ({ content, setOpenModel }) => {
  return (
    <Wrapper>
      <Container>
        <Header>
          <h1>Terms and Conditions</h1>
          <hr className="w-3/4 m-auto border-neutral-600 mt-4" />
        </Header>
        <Body>
          <p>{content}</p>
        </Body>
        <Footer>
          <button
            className="bg-emerald-500 px-4 py-2 rounded-md text-sm"
            onClick={() => setOpenModel(false)}
          >
            Close
          </button>
        </Footer>
        {/* <CgClose className="absolute right-4 top-4 te" /> */}
      </Container>
    </Wrapper>
  );
};

const Wrapper = tw.div`h-screen w-screen fixed bg-black/30 z-50 backdrop-blur-sm inset-0`;
const Container = tw.div`bg-neutral-700 fixed top-[50%] left-[50%] w-full sm:w-3/4 lg:w-1/3 p-4 sm:rounded-xl translate-x-[-50%] translate-y-[-50%] flex flex-col gap-6 text-neutral-200`;
const Header = tw.div`w-full text-center font-bold text-lg md:text-xl `;
const Body = tw.div`text-sm md:text-base`;
const Footer = tw.div`flex justify-end`;
export default Modal;
