import React, { useState } from "react";
import Model from "./Model";
import tw from "tailwind-styled-components";
import { Link } from "react-router-dom";
import { termsContent } from "./DoughnutChart";

const TermsModel = ({ setOpenTermsModel }) => {
  return (
    // <Model width={"w-11/12 max-w-lg"} setOpenModel={setOpenTermsModel}>
    //       <Title>Terms & Conditions</Title>

    //       <Content>
    //         Loan facilities borrowed under this product will be hinged on pre-existing products features.
    //         <br/><br/>
    //         In case of default of the loans; the loan product facility shall be deemed to be in arrears and normal collection Processes shall apply.
    //         <br/><br/>
    //         Processing is non-refundable.
    //         <br/><br/>
    //         Maximum Loan duration: Loan should be repayable in 1 Month (30 Days) from the day of borrowing.
    //         <br/><br/>
    //         Loan disbursement: Loan will be disbursed to the MPESA number provided & takes 5-7 working days to be processed.
    //       </Content>
    //       <Cancel onClick={() => setOpenTermsModel(false)}>Close</Cancel>

    // </Model>
    <Model content={termsContent} setOpenModel={setOpenTermsModel} />
  );
};

const Title = tw.h2` text-lg md:text-xl lg:text-2xl text-gray-600 font-medium mb-6 text-center`;
const Content = tw.div`w-full flex items-center justify-center space-x-10 mt-8`;
const Cancel = tw.button`mt-4 float-right px-8 py-3 text-sm bg-gray-100 text-gray-600 rounded hover:bg-gray-200`;

export default TermsModel;
