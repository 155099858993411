import Chart from "chart.js/auto"; // Import the Chart.js library.
import { Doughnut, Pie } from "react-chartjs-2";
import { data } from "../utilities/chartData";
import Header from "./Header";
import DoughnutChart, { randomValues } from "./DoughnutChart";
import DonutChart from "react-donut-chart";
import tw from "tailwind-styled-components";

const Hero = () => {
  // console.log(randomValues);
  return (
    <div className="img bg-image bg-cover h-full w-full lg:w-1/2 lg:rounded-r-[150px] rounded-r-xl rounded-l-xl z-{1}">
      <div className="mask bg-fuchsia-700/70 backdrop-blur-sm lg:h-full w-full lg:rounded-r-[150px] rounded-r-xl rounded-l-xl flex flex-col justify-center lg:justify-between">
        <div className="pl-[10%]">
          <Header />
        </div>
        <RowOne>
          <Title>
            Convenient, Reliable, & Transparent Mobile Loan Platform in Kenya.
          </Title>
          {/* <Title>Find the perfect loan for you.</Title> */}

          <SubTitle>
            Get approved instantly. Receive funds straight to M-PESA.
          </SubTitle>
        </RowOne>
        <RowSecond>
          <TextWrapper>
            <Heading>Loan Statistics</Heading>
            <LoanStatsItemWrapper>
              <LoanStatsItem>
                <CircleBlue /> <Text> Personal Loan</Text>
              </LoanStatsItem>
              <SmallText>{`Ksh. ${randomValues[0].toLocaleString()}`}</SmallText>
            </LoanStatsItemWrapper>
            <LoanStatsItemWrapper>
              <LoanStatsItem>
                <CircleRed /> <Text> Phone Loan</Text>
              </LoanStatsItem>
              <SmallText>{`Ksh. ${randomValues[1].toLocaleString()}`}</SmallText>
            </LoanStatsItemWrapper>
            <LoanStatsItemWrapper>
              <LoanStatsItem>
                <CircleYellow /> <Text> Car Loan</Text>
              </LoanStatsItem>
              <SmallText>{`Ksh. ${randomValues[2].toLocaleString()}`}</SmallText>
            </LoanStatsItemWrapper>
            <LoanStatsItemWrapper>
              <LoanStatsItem>
                <CircleRose /> <Text> Emergency Loan</Text>
              </LoanStatsItem>
              <SmallText>{`Ksh. ${randomValues[3].toLocaleString()}`}</SmallText>
            </LoanStatsItemWrapper>
          </TextWrapper>
          <div className="h-[200px] md:h-[200px] w-full md:w-1/2 flex justify-center items-center mt-2 mb-8">
            {/* <Doughnut data={data} width={100} height={100} /> */}
            <DoughnutChart />
          </div>
        </RowSecond>
        {/* <div className="bg-black bg-opacity-30 h-60 ml-5 mb-10 rounded-l-3xl px-2 rounded-r-full lg:flex lg:justify-center lg:items-center lg:flex-col hidden">
          <span className="text-white">A FEW CLICKS AWAY FROM</span>
          <span className="text-4xl font-bold text-gradient">
            {" "}
            UNLOCKING YOUR CREATIVITY
          </span>
        </div> */}
      </div>
    </div>
  );
};

const Title = tw.h3`text-4xl text-white text-left mb-4 mt-4  `;
const SubTitle = tw.h3`tracking-wider text-white text-base mb-4`;
const TextWrapper = tw.div`w-full md:w-1/2 place-items-left font-poppins lg:pt-0 pl-4 md:pl-8 lg:pl-16`;
const Heading = tw.h3`mt-1 sm:mt-2 tracking-wider text-white text-2xl `;
const LoanStatsItemWrapper = tw.div`w-full mt-4 items-center hidden md:block`;
const LoanStatsItem = tw.div`w-full flex flex-col md:flex-row items-center`;

const RowOne = tw.div`flex flex-col hidden md:block px-8 lg:px-16`;
const RowSecond = tw.div`flex flex-col md:flex-row mt-4 md:mt-12 md:mb-4`;

const CircleBlue = tw.div`w-5 h-5 bg-[#008DD5] rounded-full `;
const CircleRed = tw.div`w-5 h-5 bg-[#F56476] rounded-full `;
const CircleRose = tw.div`w-5 h-5 bg-[#F79D65] rounded-full `;
const CircleYellow = tw.div`w-5 h-5 bg-[#E43F6F] rounded-full `;
const Text = tw.h3`tracking-wider text-white text-base pl-2`;
const SmallText = tw.h3`tracking-wider text-slate-200 text-sm pl-7`;

export default Hero;
