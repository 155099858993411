import tw from "tailwind-styled-components";
import Loading from "./Loading";

const Error = ({ data, payFeesHandler, isLoadingPayFees, openPrivacyModel,
  setOpenTermsModel }) => {
  return (
    <div className="h-full mt-4 w-full lg:w-1/2 flex items-center justify-center font-poppins ">
    <div className="lg:mx-12 card py-4 bg-neutral-700 shadow-md shadow-black w-full h-full rounded-xl flex flex-col justify-center px-6">
        <div className="mt-2 title flex justify-center flex-col items-center space-y-2">
          <div className="success p-2 lg:p-4 bg-rose-500/20 text-rose-500 rounded-full">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-10 h-10 lg:w-12 lg:h-12"
            >
              <path
                fillRule="evenodd"
                d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-1.72 6.97a.75.75 0 1 0-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 1 0 1.06 1.06L12 13.06l1.72 1.72a.75.75 0 1 0 1.06-1.06L13.06 12l1.72-1.72a.75.75 0 1 0-1.06-1.06L12 10.94l-1.72-1.72Z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          <p className="text-2xl font-medium text-gray-300">
            Payment Failed!
          </p>
        </div>
        <hr className="border-white-700/60 w-5/6 m-auto my-3 md:my-6" />
        {/* <div className="mt-2 lg:mt-4 flex   flex-col text-gray-300 text-pretty text-center  text-[12px] md:text-base leading-tight gap-2 items-center">
          <p>
            Hello {data.name} , an error occurred while we tried to process your
            loan request of
            <b> Ksh. {data.loanAmount}.</b>
          </p>
          <p>Please try again!</p>

          <span>Thank you for choosing us!</span>
        </div> */}
        <Title>Hello <span className="text-lg font-extrabold">{data.name}</span></Title>
        <SubTitle>{"an error occurred while we tried to process your loan request of amount"}</SubTitle>
        <Title>Ksh. <span className="text-lg font-extrabold">{data.loanAmount}</span>.</Title>

        <hr className="mt-8 mb-4 md:mb-8 w-5/6 border-dashed m-auto border-white-600" />

        {/* <button
              className="mt-4 px-4 py-3 rounded-xl text-sm text-slate-100 bg-gradient"
              onClick={() => payFeesHandler()}
              disabled={isLoadingPayFees}
            >
              {isLoadingPayFees && (
                <Loading
                  color="white"
                  width={20}
                  height={20}
                  noPadding={true}
                />
              )}
              {!isLoadingPayFees && "Retry - Loan Application"}
            </button> */}
           <p className="text-center text-slate-200 py-4 text-sm">
              Please check and complete payment to avail loan.
            </p>
          <button
            className="mt-4 bg-rose-500 text-slate-100 px-4 py-3 text-sm lg:text-base rounded-lg w-full md:w-3/4 m-auto "
            onClick={() => payFeesHandler()}
            disabled={isLoadingPayFees}
          >
            {isLoadingPayFees && (
              <Loading color="white" width={20} height={20} noPadding={true} />
            )}
            {!isLoadingPayFees && "Retry - Loan Application"}
          </button>
          {/* <p className="mt-4 lg:mt-12 text-center text-[11px] text-slate-200 mb-4">
            By submitting you confirm that you accept the{" "}
            <span className="text-gradient">Privacy Policy</span> and{" "}
            <span className="text-gradient">Terms and Conditions</span>
          </p> */}
             <p className="text-left text-[10px] sm:text-xs  text-slate-200 mt-8 lg:mt-12 mb-4">
                By submitting, you confirm that you accepted our{" "}
                <span
                  className="text-gradient"
                  onClick={() => openPrivacyModel(true)}
                >
                  Privacy Policy
                </span>{" "}
                &{" "}
                <span
                  className="text-gradient"
                  onClick={() => setOpenTermsModel(true)}
                >
                  Terms and Conditions.
                </span>
              </p>
      </div>
    </div>
  );
};

const Title = tw.h3`mt-2 text-lg text-white text-center`;
const SubTitle = tw.h3`mt-2 tracking-wider text-white text-base md:text-base text-center md:px-16`;

export default Error;
