import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableHeader,
} from "./ui/table";
import { useState } from "react";

const LenderTable = ({ list, itemsPerPage }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = list.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  return (
    <>
      <Table className="text-center lg:text-base sm:text-sm text-xs h-[180px]">
        <TableHeader className="bg-neutral-900">
          <TableRow>
            <TableHead className="font-bold text-center text-slate-100 border border-neutral-800">
              Lender's Name
            </TableHead>
            <TableHead className="font-bold text-center text-slate-100 border border-neutral-800">
              Lender's E-mail
            </TableHead>
            <TableHead className="font-bold text-center text-slate-100 border border-neutral-800">
              Lender's Ph. No.
            </TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {currentItems.map((lender, idx) => (
            <TableRow key={idx}
              className={`${
                idx & 1 ? "bg-neutral-700" : "bg-neutral-800"
              } text-slate-100 `}
            >
              <TableCell className="font-bold border-neutral-800 border">
                {lender.name}
              </TableCell>
              <TableCell className="border border-neutral-800">
                {lender.email}
              </TableCell>
              <TableCell className="border border-neutral-800">
                {lender.phone}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div>
        {Array.from({
          length: Math.ceil(list.length / itemsPerPage),
        }).map((page, index) => (
          <button
            key={index}
            onClick={() => paginate(index + 1)}
            className={`bg-neutral-700 text-white py-2 px-4 text-xs rounded-lg mx-2  ${
              list.length <= 3 ? "hidden" : ""
            } ${currentPage === index + 1 ? "" : "bg-slate-100 text-black"} `}
          >
            {index + 1}
          </button>
        ))}
      </div>
    </>
  );
};
export default LenderTable;
