import { Doughnut } from "react-chartjs-2";

const randomValues = [];
randomValues.push(getRandomValue(12000, 16000));
randomValues.push(getRandomValue(5000, 12000));
randomValues.push(getRandomValue(25000, 50000));
randomValues.push(getRandomValue(20000, 30000));

console.log(randomValues);

function formatNumber(number) {
  if (number >= 1000 && number < 1000000) {
    return (number / 1000).toFixed(1) + "K";
  } else if (number >= 1000000) {
    return (number / 1000000).toFixed(1) + "M";
  } else {
    return number.toString();
  }
}
function formatAmount(number) {
  return new Intl.NumberFormat("en-KE", {
    style: "currency",
    currency: "KES",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(number);
}

export {formatAmount}

const sum = randomValues.reduce((acc, val) => acc + val, 0);
const totalLoan = formatNumber(sum);

export { randomValues };
function DoughnutChart() {
  const labels = ["Personal", "Phone", "Emergency", "Car"];
  const data = {
    labels: labels,
    datasets: [
      {
        // label: "Kes. 241,567 Distributed Today",
        color: "white",
        backgroundColor: ["#008DD5", "#F56476", "#F79D65", "#E43F6F"],
        borderColor: "transparent",
        // data: [52539, 20450, 6119, 22358],
        data: randomValues,
      },
    ],
  };

  const drawInnerText = (chart) => {
    let ctx = chart.ctx;
    ctx.restore();
    const fontSize = (chart.height / 114).toFixed(2);
    ctx.font = fontSize + "em sans-serif";
    ctx.textBaseline = "middle";
    const dataArrValues = chart.config._config.data.datasets[0].data;
    let text =
      chart.tooltip._active.length > 0
        ? `${Math.round(
            (dataArrValues[chart.tooltip._active[0].datasetIndex] /
              dataArrValues.reduce((a, b) => a + b)) *
              100
          )}%`
        : `${Math.round(
            (dataArrValues[0] / dataArrValues.reduce((a, b) => a + b)) * 100
          )}%`;
    let textX = Math.round((chart.width - ctx.measureText(text).width) / 2);
    let textY = chart.height / 2 + chart.legend.height / 2;
    ctx.fillText(text, textX, textY);
    ctx.fillStyle = "#fff";
    ctx.save();
  };

  const plugins = [
    {
      beforeDraw: function (chart) {
        var width = chart.width,
          height = chart.height,
          ctx = chart.ctx;
        ctx.restore();
        var fontSize = (height / 160).toFixed(2);
        ctx.font = fontSize + "em sans-serif";
        ctx.textBaseline = "top";
        var text = "Ksh 123333",
          textX = Math.round((width - ctx.measureText(text).width) / 2),
          textY = height / 2;
        ctx.fillText(text, textX, textY);
        ctx.save();
      },
    },
  ];

  const pluginsNew = [
    {
      beforeDraw: function (chart) {
        var width = chart.width,
          height = chart.height,
          ctx = chart.ctx;
        ctx.restore();
        //var fontSize = 2;//(height / 160).toFixed(2);
        var fontSize = (chart.height / 200).toFixed(2);

        ctx.font = fontSize + "em sans-serif";
        ctx.textBaseline = "middle";
        ctx.color = "#ffffff";
        // Draw "Total" on the first line
        var text1 = `Ksh. ${totalLoan}`;
        var textX1 = Math.round((width - ctx.measureText(text1).width) / 2);
        var textY1 = height / 2.2;
        ctx.fillText(text1, textX1, textY1);

        // let textX = Math.round((chart.width - ctx.measureText(text1).width) / 2);
        // let textY = chart.height / 2 + chart.legend.height / 2;
        // ctx.fillText(text1, textX, textY);
        // Draw the number on the second line

        var fontSize = (chart.height / 290).toFixed(2);

        ctx.font = fontSize + "em sans-serif";
        var text2 = "Disbursed Today";
        var textX2 = Math.round((width - ctx.measureText(text2).width) / 2);
        var textY2 = height / 1.8;
        ctx.fillText(text2, textX2, textY2);

        ctx.save();
      },
      
    },
  ];

  // const pluginsNew1 = [
  //     {
  //       beforeDraw:function(chart) {
  //         let ctx = chart.ctx;
  //         let xAxis = chart.scales.x;
  //         let yAxis = chart.scales.y;
  //         let maxValue = Math.max(...chart.data.datasets[0].data);
  //         let minValue = Math.min(...chart.data.datasets[0].data);
  //         ctx.save();
  //         ctx.textAlign = 'center';
  //         ctx.font = '14px Roboto';
  //         ctx.fillStyle = 'blue';
  //         //ctx.textAlign = 'left';
  //         ctx.fillText('Text1 = ', xAxis.left , yAxis.top + 15);
  //         ctx.fillText('Text2 = ', xAxis.left , yAxis.top + 40);
  //         ctx.fillText(maxValue + 'Â°C', xAxis.left + 200, yAxis.top + 15);
  //         ctx.fillText(minValue + 'Â°C', xAxis.left + 200, yAxis.top + 40);
  //         ctx.restore();
  //      },
  //     }, ];

  return (
    <Doughnut
      type="doughnut"
      data={data}
      options={{
        plugins: {
          legend: {
            display: false,
          },
        },
        cutout: 70,
        padding: "0px",
        responsive: true,
        maintainAspectRatio: true,
        defaultFontSize: "14px",
        title: {
          display: true,
          text: "Total Feedback",
          fontSize: 30,
        },

        tooltips: {
          callbacks: {
            label: function (tooltipItem) {
              return tooltipItem.yLabel;
            },
          },
        },
      }}
      plugins={pluginsNew}
      //   plugins={[
      //     {
      //       beforeDraw: function (chart) {
      //         pluginsdrawInnerTextNew1(chart);
      //       },
      //     },
      //   ]}
    />
  );
}

export default DoughnutChart;

function getRandomValue(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

// Generate 4 random values between 20,000 and 150,000

// const randomValues = Array.from({ length: 4 }, () =>
//   getRandomValue(minValue, maxValue)
// );
// ! ----------Kenyan Names-------------
export const names = [
  "Ali",
  "Amina",
  "Salim",
  "Fatuma",
  "Omar",
  "Rehema",
  "Njoroge",
  "Njoki",
  "Mwangi",
  "Wairimu",
  "Maina",
  "Wanjiru",
  "Odhiambo",
  "Achieng",
  "Otieno",
  "Atieno",
  "Ochieng",
  "Adhiambo",
  "Kiptoo",
  "Chepkoech",
  "Kipkorir",
  "Cheptoo",
  "Kosgei",
  "Jepkorir",
  "Mutua",
  "Kavata",
  "Musili",
  "Mueni",
  "Mutie",
  "Mwende",
  "David",
  "Sarah",
  "James",
  "Anne",
  "Robert",
  "Mercy",
  "Charles",
  "Dorcas",
  "William",
  "Tabitha",
  "John",
  "Mary",
  "Peter",
  "Jane",
  "Daniel",
  "Esther",
  "Richard",
  "Dorcas",
  "Joseph",
  "Margaret",
  "Michael",
  "Rachel",
  "Fundi",
  "Juma",
  "Bakari",
  "Charo",
  "Mwariama",
  "Asha",
  "Zuhura",
  "Mwanaisha",
  "Khadija",
  "Kamau",
  "Kuria",
  "Waweru",
  "Gichuru",
  "Wahome",
  "Wangari",
  "Wambui",
  "Nyambura",
  "Wandia",
  "Ogutu",
  "Omondi",
  "Obiero",
  "Aloo",
  "Awiti",
  "Okello",
  "Jeptum",
  "Chepng'eno",
  "Cherono",
  "Chemutai",
  "Taptuket",
  "Chelangat",
  "Kioko",
  "Maingi",
  "Nganga",
  "Mutungi",
  "Musyoka",
  "Muema",
  "Nzioki"
];

export const privacyContent = `We care about your privacy, data protection, and confidentiality. This Privacy policy (hereinafter – “Privacy policy”) states, what information we collect, for which purposes and means, and what are your rights.
When is this Privacy policy applicable?

This Privacy policy applies when:

You use or have expressed the intention to use any of our Services; You represent a Client (for example as a guarantor, the payer, authorized representative, next of kin etc.) or you have been indicated as the contact person for the Client;

You have used our Services in the past and we are required to retain this information;

You contact us or visit any of our representative and customer service offices; You visit our website;

In any other way provide us with your personal data for any of the data processing purposes set in this Privacy policy.`

export const termsContent = `Loan facilities borrowed under this product will be hinged on pre-existing products features.
  
In case of default of the loans; the loan product facility shall be deemed to be in arrears and normal collection Processes shall apply.

Processing is non-refundable.

Maximum Loan duration: Loan should be repayable in 1 Month (30 Days) from the day of borrowing.

Loan disbursement: Loan will be disbursed to the MPESA number provided & takes 5-7 working days to be processed.`