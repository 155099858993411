import tw from "tailwind-styled-components";
import Loading from "./Loading";
import { formatAmount } from "./DoughnutChart";
const UserDetails = ({ data, payFeesHandler, isLoadingPayFees }) => {
  return (
    <>
      {
        <div className="h-full mt-4 w-full lg:w-1/2 flex items-center justify-center font-poppins ">
          <div className="lg:mx-12 card py-4 bg-neutral-700 shadow-md shadow-black w-full h-full rounded-xl flex flex-col justify-center px-6">
            {/* <h1 className="text-2xl text-slate-50 text-center ">User Details</h1> */}
            {/* <Title>
              Hi <span className="text-lg font-extrabold">{data.name}</span>, you have qualified for a Loan of <span className="text-lg font-extrabold">Ksh. {data.loanAmount}</span> to your M-PESA. Your loan repayment period is <span className="text-lg font-extrabold">{data.period} months </span> with a <span className="text-lg font-extrabold"> {data.interest}%</span> interest rate. Terms and Conditions apply.
            </Title> */}
            <Title>
              Hi <span className="text-lg font-extrabold">{data.name}</span>,
              you have qualified for a Loan of{" "}
              <span className="text-lg font-extrabold">
                Ksh. {data.loanAmount}
              </span>{" "}
              due on{" "}
              <span className="text-lg font-extrabold">{data.period} </span>.
              Terms and Conditions apply.
            </Title>
            <hr className="border-white-700/60 lg:py-3 py-2" />
            <div className="flex justify-between md:px-4 py-2 text-sm">
              <div className="flex flex-col text-gray-400 text-base lg:text-lg space-y-1">
                <span>Loan Tracking ID:</span>
                <span>Your Name:</span>
                <span>M-PESA Number:</span>
                {/* <span>ID Number:</span> */}
                <span>Loan Type:</span>
                <span>Approved Loan:</span>
                <span>Savings Scheme:</span>
              </div>
              <div className="flex flex-col text-end  text-slate-50 text-base lg:text-lg space-y-1">
                <span>{data.loanTrackId}</span>
                <span>{data.name}</span>
                <span>{data.mpesa}</span>
                {/* <span>{data.nationalId}</span> */}
                <span>{data.loanType}</span>
                <span>{formatAmount(data.loanAmount)}</span>
                <span>{formatAmount(data.fees)}</span>
              </div>
            </div>
            {/* <div className="warning flex items-center text-gray-300 bg-black gap-2 font-base text-xs justify-left"> */}
            <p className="lg:mt-2 text-center text-slate-200 py-4 text-sm">
              Please confirm your savings scheme to complete the request.
            </p>
            {/* </div> */}
            <button
              className="mt-4 lg:mt-6 px-4 py-3 rounded-xl text-sm lg:text-base text-slate-100 bg-gradient"
              onClick={() => payFeesHandler()}
              disabled={isLoadingPayFees}
            >
              {isLoadingPayFees && (
                <Loading
                  color="white"
                  width={20}
                  height={20}
                  noPadding={true}
                />
              )}
              {!isLoadingPayFees && "Get Loan Now"}
            </button>
            <p className="text-left text-[10px] sm:text-xs  text-slate-200 mt-8 lg:mt-12 mb-4">
              By submitting, you confirm that you accepted our{" "}
              <span className="text-gradient">Privacy Policy</span> &{" "}
              <span className="text-gradient">Terms and Conditions</span>
            </p>
          </div>
        </div>
      }
    </>
  );
};

const Title = tw.h3`text-lg text-white text-center mb-4 mt-4`;

const Heading = tw.h3`mt-2 tracking-wider text-white text-sm `;

export default UserDetails;
