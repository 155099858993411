import { Chart, Colors } from "chart.js";


const labels = ["Personal", "Phone", "Emergency", "Car"];
const data = {
    labels: labels,
    datasets: [
      {
        // label: "Kes. 241,567 Distributed Today",
        color:"white",
        backgroundColor: ["#008DD5", "#F56476","#E43F6F","#F79D65"],
        borderColor: "transparent",
        data: [52539, 20450, 6119, 22358],
      },
    ],
    text: "Total: 9000+"
  };
  
  Chart.defaults.color = "#ffffff";
  export {data};

