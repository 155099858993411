import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging/sw";
import { getToken } from "firebase/messaging";
import { onMessage } from "firebase/messaging";

//Firebase Config values imported from .env file
// console.log(process.env);

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

export async function registerSw() {
  const registration = await navigator.serviceWorker.register(
    "/firebase-messaging-sw.js"
  );
  return registration;
}

export async function reqPermission(registration) {
  try {
    const permission = await Notification.requestPermission();
    console.log(permission);
    if (permission === "granted" && messaging) {
      console.log(registration);

      if (registration && registration.active.state === "activated") {
        const token = await getToken(messaging, {
          serviceWorkerRegistration: registration,
          vapidKey: process.env.REACT_APP_VAPID_ID,
        });
        console.info(`%c${token}`, `color:lime; font-style:italic;`);
        return token;
      } else {
        return null;
      }
    } else if (permission === "denied") {
      console.log("Notification Permission Denied.");
      return null;
    }
  } catch (err) {
    console.log(err.message);
    setTimeout(() => {
      reqPermission(registration);
    }, 1000);
  }
}

// export const onMessageListener = () =>
//   new Promise((resolve) => {
//     onMessage(messaging, (payload) => {
//       resolve(payload);
//     });
//   });
